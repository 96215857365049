<div class="document-menu" *ngIf="{ documents: documents$ | async, isLoading: isLoading$ | async } as data">
  <ng-container *ngIf="data.isLoading === false; else loading">
    <div *ngIf="data.documents.length > 0">
      <button mat-button color="primary" disableRipple="true" [matMenuTriggerFor]="documentMenu">
        <div>
          <mat-icon> cloud_download</mat-icon>
        </div>
        <span>{{ 'sidebar.documents' | transloco }}</span>
      </button>
      <mat-menu #documentMenu="matMenu">
        <button mat-menu-item *ngFor="let document of data.documents" (click)="downloadDocument(document)">
          {{ document.filename }}
        </button>
      </mat-menu>
    </div>
  </ng-container>
  <ng-template #loading>
    <mat-spinner class="white-spinner" [diameter]="30"></mat-spinner>
  </ng-template>
</div>
