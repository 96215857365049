import { Type } from '@angular/core';

import { AssetEffects } from './assets.effect';
import { CategoryEffects } from './categories.effect';
import { CurrencyEffects } from './currencies.effect';
import { DocumentEffect } from './documents.effect';
import { ProjectEffects } from './projects.effect';
import { TemplatesEffect } from './templates.effect';

export const entitiesEffects: Array<
  Type<AssetEffects | CategoryEffects | CurrencyEffects | ProjectEffects | DocumentEffect | TemplatesEffect>
> = [AssetEffects, CategoryEffects, CurrencyEffects, ProjectEffects, DocumentEffect, TemplatesEffect];
