import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AvailableLang } from '@app/core/const/i18n';
import { Document } from '@app/core/models/document';
import { downloadFile } from '@app/core/tools/file-saver';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const DOCUMENT_ENDPOINT = `${environment.backend_url}documents`;

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(private readonly _httpClient: HttpClient) {}

  getUserDocuments(userId: string, language: AvailableLang): Observable<Document[]> {
    return this._httpClient.get<Document[]>(DOCUMENT_ENDPOINT, { params: { UserId: userId, language } });
  }

  downloadDocument(
    document: Document,
    downloadFileFn: (data: ArrayBuffer | string, type: string, fileName: string) => void = downloadFile
  ): Observable<void> {
    return this._httpClient
      .get(`${DOCUMENT_ENDPOINT}/${document.id}`, {
        observe: 'response',
        responseType: 'arraybuffer',
      })
      .pipe(
        map((resp: HttpResponse<ArrayBuffer>) => {
          const disposition = resp.headers.get('Content-Disposition');
          const filename =
            disposition != null && disposition !== ''
              ? disposition.replace(/^attachment;\s*(filename\*?)=([^"']*)(["']*)([^"']+)(["']?).*$/i, '$4')
              : `${document.filename}-${document.language}.pdf`; // assume pdf if no content disposition provided

          downloadFileFn(resp.body, resp.headers.get('content-type'), filename);
          return;
        })
      );
  }
}
