import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AdministrationDocumentDto } from '../models/administration-document';

const ENDPOINT = `${environment.backend_url}administration/documents`;

@Injectable({
  providedIn: 'root',
})
export class AdministrationDocumentService {
  constructor(private readonly _httpClient: HttpClient) {}

  uploadDocument(dto: AdministrationDocumentDto): Observable<void> {
    const formData = new FormData();
    formData.append('file', dto.file);
    formData.append('data', JSON.stringify(dto.data));

    return this._httpClient.post<void>(ENDPOINT, formData);
  }

  deleteDocument(id: number): Observable<void> {
    return this._httpClient.delete<void>(`${ENDPOINT}/${id}`);
  }
}
