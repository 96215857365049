import { Component } from '@angular/core';
import { DocumentsBaseComponent } from '@app/core/components/documents-base/documents-base.component';
import * as fromRoot from '@app/store/reducers';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';

@Component({
  selector: 'am-document-menu',
  templateUrl: './document-menu.component.html',
  styleUrl: './document-menu.component.scss',
})
export class DocumentMenuComponent extends DocumentsBaseComponent {
  constructor(
    readonly _store: Store<fromRoot.State>,
    readonly _translocoService: TranslocoService
  ) {
    super(_store, _translocoService);
  }
}
