import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from 'src/environments/environment';

function formatNonErrorException(event: Sentry.Event): any {
  // Note: issue with double entries during http exceptions: https://github.com/getsentry/sentry-javascript/issues/2169
  // eslint-disable-next-line max-len
  // Note: issue with a second entry not being set correctly (as a non-error): https://github.com/getsentry/sentry-javascript/issues/2292#issuecomment-554932519
  const isNonErrorException = event.exception.values[0].value.startsWith('Non-Error exception captured');
  if (isNonErrorException) {
    if (!event.extra.__serialized__) {
      return null;
    }
    // @ts-ignore
    let realErrMsg = event.extra.__serialized__.error ? event.extra.__serialized__.error.message : null;
    // @ts-ignore
    realErrMsg = realErrMsg || event.extra.__serialized__.message;
    // this is a useless error message that masks the actual error.  Lets try to set it properly
    event.exception.values[0].value = realErrMsg;
    event.message = realErrMsg;
  }
  return event;
}

@Injectable({ providedIn: 'root' })
export class SentryService {
  constructor() {
    Sentry.init({
      dsn: 'https://2f3d9a63ac4141b2a4c6526444ed4d31@o423307.ingest.sentry.io/5353399',
      release: `allocation-designer@$1.1.0`,
      environment: environment.sentryEnv,
      integrations: [Sentry.extraErrorDataIntegration({ depth: 9 })],
      normalizeDepth: 10,
      beforeSend(event) {
        return formatNonErrorException(event);
      },
    });
  }

  captureException(error: Error | HttpErrorResponse | string): void {
    Sentry.captureException(error);
    // When in development mode, log the error to console for immediate feedback.
    if (!environment.production) {
      console.error(error);
    }
  }

  captureExceptionWithExtra(error: Error | HttpErrorResponse | string, extra: { key: string; value: unknown }): void {
    Sentry.withScope(scope => {
      scope.setExtra(extra.key, extra.value);
      Sentry.captureException(error);
    });
    // When in development mode, log the error to console for immediate feedback.
    if (!environment.production) {
      console.error(error);
    }
  }
}
