import { Injectable } from '@angular/core';
import { AdministrationTemplateService } from '@app/admin/services/administration-template.service';
import { AppActions } from '@app/store/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import { TemplatesActions } from '../actions';

@Injectable()
export class TemplatesEffect {
  uploadTemplate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TemplatesActions.uploadTemplate),
      switchMap(({ dto }) => {
        return this._administrationTemplateService
          .uploadTemplate(dto)
          .pipe(map(() => TemplatesActions.uploadTemplateSuccess()));
      })
    )
  );

  uploadTemplateSuccess$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(TemplatesActions.uploadTemplateSuccess),
      map(() => {
        return AppActions.httpSuccess({ messageKey: { key: 'success.upload-template' } });
      })
    );
  });

  uploadTemplateFailure$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(TemplatesActions.uploadTemplateFailure),
      map(() => AppActions.httpFailure({ messageKey: { key: 'errors.upload-template' } }))
    );
  });

  constructor(
    private readonly _actions$: Actions,
    private readonly _administrationTemplateService: AdministrationTemplateService
  ) {}
}
