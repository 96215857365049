import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AvailableCountry } from '@app/core/const/country';
import { ProjectsActions } from '@app/core/entities/actions';
import { getDocuments } from '@app/core/entities/reducers';
import { Document } from '@app/core/models/document';
import * as fromRoot from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'am-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Output() closeSidenav = new EventEmitter<void>();

  country$: Observable<AvailableCountry>;

  userIsAdmin$: Observable<boolean>;

  isSimulationLinkActive: boolean;

  documents$: Observable<Document[]>;

  private _urlPath: string;

  constructor(
    private readonly _router: Router,
    private readonly _store: Store<fromRoot.State>
  ) {
    this.documents$ = this._store.select(getDocuments);
  }

  @Input()
  get urlPath(): string {
    return this._urlPath;
  }
  set urlPath(value: string) {
    this._urlPath = value;
    this.isSimulationLinkActive = this._urlPath === '/simulation' || this._urlPath === '/analysis';
  }

  ngOnInit(): void {
    this.userIsAdmin$ = this._store.select(fromRoot.getCurrentUserIsAdmin);
    this.country$ = this._store.select(fromRoot.getCountry);
  }

  emitClose(): void {
    this.closeSidenav.emit();
  }

  onClickNavigation(route: string): void {
    if (this.isSimulationLinkActive) {
      this._store.dispatch(ProjectsActions.resetSelectedProject({ route }));
    } else {
      this._router.navigate([route]);
    }
  }
}
