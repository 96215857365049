import {
  APP_BASE_HREF,
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SentryErrorHandler } from '@app/core/error-handlers/sentry-error.handler';
import { appEffects } from '@app/store/effects';
import * as fromRoot from '@app/store/reducers';
import { provideTransloco, TranslocoModule } from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AVAILABLE_COUNTRIES, AvailableCountry } from './core/const/country';
import { AvailableLang, getDefaultLang } from './core/const/i18n';
import { CoreModule } from './core/core.module';
import { entitiesEffects } from './core/entities/effects';
import { HttpLoader } from './http-loader';
import { SharedModule } from './shared/shared.module';
import { AuthActions } from './store/actions';

function getBaseLocation(): string {
  const url = new URL(location.href);
  const queryHasAuthParams = url.searchParams;
  const relayState = queryHasAuthParams.get('RelayState');
  if (relayState && AVAILABLE_COUNTRIES.find(availableCountry => availableCountry === relayState)) {
    return '/' + relayState;
  }

  const paths: string[] = location.hash.split('/').splice(1, 1);
  const basePath: string =
    paths?.[0] && AVAILABLE_COUNTRIES.find(availableCountry => availableCountry === paths[0])
      ? paths[0]
      : AvailableCountry.fra;
  return '/' + basePath;
}

export function authInitializer(store: Store<fromRoot.State>) {
  return (): void => {
    const url = new URL(location.href);
    if (url.hash.match(new RegExp('.*\\/logout\\/?$'))) {
      store.dispatch(AuthActions.logoutUserSuccess());
      return;
    }

    const queryHasAuthParams = url.searchParams;
    const samlResponse = queryHasAuthParams.get('SAMLResponse');

    if (samlResponse) {
      store.dispatch(AuthActions.authenticateUser({ samlResponse }));
    }
  };
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    TranslocoModule,
    StoreModule.forRoot(fromRoot.rootReducers, {
      runtimeChecks: {
        strictActionSerializability: false,
        strictStateSerializability: false,
        strictActionTypeUniqueness: false,
        strictActionImmutability: false,
        strictStateImmutability: false,
        strictActionWithinNgZone: false,
      },
    }),
    EffectsModule.forRoot([...entitiesEffects, ...appEffects]),
    StoreDevtoolsModule.instrument({
      name: 'Store DevTools',
      serialize: true,
      logOnly: environment.reduxLogOnly,
    }),
  ],
  providers: [
    { provide: LocationStrategy, useClass: environment.useHash ? HashLocationStrategy : PathLocationStrategy },
    { provide: APP_BASE_HREF, useValue: getBaseLocation() },
    {
      provide: APP_INITIALIZER,
      useFactory: authInitializer,
      multi: true,
      deps: [Store],
    },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    provideTransloco({
      config: {
        availableLangs: [AvailableLang.en, AvailableLang.fr, AvailableLang.es, AvailableLang.nl],
        defaultLang: getDefaultLang(),
        reRenderOnLangChange: true,
        fallbackLang: AvailableLang.en,
      },
      loader: HttpLoader,
    }),
    {
      provide: MATERIAL_SANITY_CHECKS,
      useValue: {
        doctype: true,
        // disable theme sanity checks because it returns a false negative, we know that the theme has been loaded so there is no need for this check.
        theme: false,
        version: true,
        hammer: true,
      },
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
