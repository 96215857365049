import { Injectable } from '@angular/core';
import { BacktestActions } from '@app/analysis-results/actions';
import {
  AssetsActions,
  CategoriesActions,
  CurrenciesActions,
  DocumentsActions,
  ProjectsActions,
} from '@app/core/entities/actions';
import * as fromEntities from '@app/core/entities/reducers';
import { Asset, Category, Currency, Histories, Project } from '@app/core/models';
import { Document } from '@app/core/models/document';
import { TelemetryService } from '@app/core/services';
import { CsvExportService } from '@app/core/services/csv-export.service';
import { PptxExportService } from '@app/core/services/pptx-export.service';
import { CHART_TYPE_STORAGE_KEY } from '@app/shared/components';
import { SnackBarService } from '@app/shared/services';
import { AppActions, ImportsActions } from '@app/store/actions';
import { updateChartType, updateChartTypeSuccessful, updateLanguage } from '@app/store/actions/app.action';
import * as fromRoot from '@app/store/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { Action, select, Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import moment from 'moment';
import { throwError } from 'rxjs';
import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

@Injectable()
export class AppEffects {
  httpFailure$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AppActions.httpFailure),
        tap(error => {
          this._snackBarService.openSnackBar('error', [error.messageKey], undefined, 5000);
        })
      ),
    { dispatch: false }
  );

  httpSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AppActions.httpSuccess),
        tap(success => {
          this._snackBarService.openSnackBar('success', [success.messageKey], undefined, 5000);
        })
      ),
    { dispatch: false }
  );

  exportAnalysisResults$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AppActions.exportAnalysisResults),
      withLatestFrom(
        this._store.select(fromEntities.getAllAssets),
        this._store.select(fromEntities.getAvailableLiquidities),
        this._store.select(fromRoot.getCountry),
        this._store.select(fromRoot.getCurrentLang),
        this._store.select(fromRoot.getChartType)
      ),
      switchMap(([_, assetsList, availableLiquidities, country, currentLang, chartType]) => {
        return this._store.pipe(
          select(fromEntities.getSelectedProject),
          filter(selectedProject => !!selectedProject && !!selectedProject.backtestResults),
          take(1),
          switchMap(selectedProject => {
            if (!selectedProject || selectedProject.allocations.length < 2) {
              return [AppActions.exportAnalysisResultsFailure({})];
            }
            return this._pptxExportService
              .getAnalysisResultsExport(
                selectedProject,
                assetsList,
                availableLiquidities,
                country,
                currentLang,
                chartType
              )
              .pipe(
                map(() => AppActions.exportAnalysisResultsSuccess()),
                catchError(error => {
                  const messageKey = JSON.parse(error.message);
                  this._store.dispatch(AppActions.exportAnalysisResultsFailure({ error: messageKey }));
                  return throwError(error);
                })
              );
          })
        );
      })
    )
  );

  exportAnalysisResultsFailure$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AppActions.exportAnalysisResultsFailure),
        tap(({ error }) => {
          if (error != null) {
            this._snackBarService.openSnackBar('error', [error], undefined, 5000);
          } else {
            this._snackBarService.openSnackBar(
              'error',
              [{ key: 'errors.export.missing-allocations' }],
              undefined,
              5000
            );
          }
        })
      ),
    { dispatch: false }
  );

  exportProjectAsCsv$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AppActions.exportProjectAsCsv),
        withLatestFrom(this._store.select(fromEntities.getAllAssets), this._store.select(fromRoot.getCurrentLang)),
        switchMap(([_, assets, currentLang]) => {
          return this._store.pipe(
            select(fromEntities.getSelectedProject),
            filter(selectedProject => !!selectedProject && !!selectedProject.backtestResults),
            take(1),
            tap(
              selectedProject => {
                this._csvExportService.exportProjectAsCsv(selectedProject, assets, currentLang);
              },
              error => {
                const messageKey = JSON.parse(error.message);
                this._store.dispatch(AppActions.httpFailure({ messageKey }));
                return throwError(error);
              }
            )
          );
        })
      ),
    { dispatch: false }
  );

  runBacktestBeforeExport$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AppActions.exportProjectAsCsv, AppActions.exportAnalysisResults),
      withLatestFrom(
        this._store.pipe(
          select(fromEntities.getSelectedProject),
          filter(selectedProject => !!selectedProject && !selectedProject.backtestResults)
        )
      ),
      distinctUntilKeyChanged('1', (previousProject, project) => isEqual(previousProject, project)),
      switchMap(([action]) => [action, BacktestActions.runBacktest({})])
    )
  );

  sendTelemetryEvent$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AppActions.sendTelemetryEvent),
        withLatestFrom(this._store.select(fromRoot.getCurrentUser), this._store.select(fromRoot.getCountry)),
        mergeMap(([{ telemetryData }, currentUser, country]) => {
          const tags = { ...telemetryData.tags, country };
          return this._telemetryService.sendEvent({ ...telemetryData, userId: currentUser.nameId, tags });
        })
      ),
    { dispatch: false }
  );

  routerNavigated$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      filter((action: RouterNavigationAction) => action.payload.event.url !== '/login'),
      withLatestFrom(
        this._store.select(fromEntities.getAllProjects),
        this._store.select(fromEntities.getAllAssets),
        this._store.select(fromEntities.getAllCategories),
        this._store.select(fromEntities.getAllCurrencies),
        this._store.select(fromEntities.getFxRatesHistories),
        this._store.select(fromRoot.getUpdateEntitiesAt),
        this._store.select(fromEntities.getDocuments)
      ),
      switchMap(([action, projects, assets, categories, currencies, fxRatesHistories, updateEntitiesAt, documents]) => {
        const actionsToDispatchToUpdate =
          updateEntitiesAt && moment(updateEntitiesAt).isBefore(moment())
            ? this._getActionsToDispatchToUpdate(assets, categories, currencies, fxRatesHistories, documents)
            : [];
        return [
          ...this._getActionsToDispatchOnNavigation(
            action.payload.event.url,
            projects,
            assets,
            categories,
            currencies,
            documents
          ),
          ...actionsToDispatchToUpdate,
        ];
      })
    )
  );

  updateLanguage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateLanguage),
      map(() => DocumentsActions.loadDocuments())
    )
  );

  updateChartType$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateChartType),
      map(({ chartType }) => {
        localStorage.setItem(CHART_TYPE_STORAGE_KEY, chartType);

        return updateChartTypeSuccessful({ chartType });
      })
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<fromRoot.State>,
    private readonly _snackBarService: SnackBarService,
    private readonly _pptxExportService: PptxExportService,
    private readonly _csvExportService: CsvExportService,
    private readonly _telemetryService: TelemetryService
  ) {}

  private _getActionsToDispatchOnNavigation(
    url: string,
    projects: Project[],
    assets: Asset[],
    categories: Category[],
    currencies: Currency[],
    documents: Document[]
  ): Array<Action<string>> {
    const actions: Array<Action<string>> = [];
    const entitiesToLoadForUrls = {
      project: ['/', '/simulation'],
      asset: ['/', '/simulation', '/forecast'],
      category: ['/simulation'],
      currency: ['/simulation', '/forecast'],
      document: ['/', '/simulation', '/forecast'],
    };
    const entityNeedsToBeLoaded = (
      entity: Array<Asset | Category | Currency | Project | Document>,
      entityName: 'asset' | 'category' | 'currency' | 'project' | 'document'
    ): boolean => {
      return (!entity || entity.length === 0) && entitiesToLoadForUrls[entityName].includes(url);
    };
    if (entityNeedsToBeLoaded(projects, 'project')) {
      actions.push(ProjectsActions.loadUserProjects());
    }
    if (entityNeedsToBeLoaded(assets, 'asset')) {
      actions.push(AssetsActions.loadAssets());
    }
    if (entityNeedsToBeLoaded(categories, 'category')) {
      actions.push(CategoriesActions.loadCategories());
    }
    if (entityNeedsToBeLoaded(currencies, 'currency')) {
      actions.push(CurrenciesActions.loadCurrencies());
    }
    if (entityNeedsToBeLoaded(documents, 'document')) {
      actions.push(DocumentsActions.loadDocuments());
    }
    if (url === '/analysis') {
      actions.push(AssetsActions.loadAssetsHistories());
      actions.push(CurrenciesActions.loadFxRatesHistories());
    }
    actions.push(ImportsActions.getAllImportsDates());
    return actions;
  }

  private _getActionsToDispatchToUpdate(
    assets: Asset[],
    categories: Category[],
    currencies: Currency[],
    fxRatesHistories: Histories[],
    documents: Document[]
  ): Array<Action<string>> {
    const actions: Array<Action<string>> = [];
    const entityNotEmpty = (entity: unknown[]): boolean => entity && entity.length > 0;

    if (entityNotEmpty(assets)) {
      actions.push(AssetsActions.loadAssets());
    }
    if (entityNotEmpty(categories)) {
      actions.push(CategoriesActions.loadCategories());
    }
    if (entityNotEmpty(currencies)) {
      actions.push(CurrenciesActions.loadCurrencies());
    }
    if (entityNotEmpty(fxRatesHistories)) {
      actions.push(CurrenciesActions.loadFxRatesHistories());
    }
    if (entityNotEmpty(documents)) {
      actions.push(DocumentsActions.loadDocuments());
    }
    actions.push(AppActions.setUpdateEntitiesAt({ updateEntitiesAt: undefined }));
    return actions;
  }
}
