import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AdministrationTemplateDto } from '../models/administration-template';

const TEMPLATE_ENDPOINT = `${environment.base_sr_tools_url}administration/pptx-templates`;

@Injectable({
  providedIn: 'root',
})
export class AdministrationTemplateService {
  constructor(private readonly _httpClient: HttpClient) {}

  uploadTemplate(dto: AdministrationTemplateDto): Observable<void> {
    const formData = new FormData();
    formData.append('file', dto.file);
    formData.append('data', JSON.stringify(dto.data));

    return this._httpClient.post<void>(TEMPLATE_ENDPOINT, formData);
  }
}
