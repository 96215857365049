import { Injectable } from '@angular/core';
import { AdministrationDocumentService } from '@app/admin/services/administration-document.service';
import { DocumentService } from '@app/core/services/document.service';
import { AppActions } from '@app/store/actions';
import * as fromRoot from '@app/store/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { DocumentsActions } from '../actions';

@Injectable()
export class DocumentEffect {
  loadDocuments$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        DocumentsActions.loadDocuments,
        DocumentsActions.uploadDocumentSuccess,
        DocumentsActions.deleteDocumentSuccess
      ),
      withLatestFrom(this._store.select(fromRoot.getCurrentUser), this._store.select(fromRoot.getCurrentLang)),
      filter(([_, currentUser, lang]) => currentUser != null && lang != null),
      switchMap(([_, currentUser, lang]) =>
        this._documentService.getUserDocuments(currentUser?.nameId, lang).pipe(
          map(documents =>
            DocumentsActions.loadDocumentsSuccess({
              documents,
            })
          ),
          catchError(error => throwError(error))
        )
      )
    )
  );

  downloadDocument$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(DocumentsActions.downloadDocument),
        tap(action => {
          this._documentService.downloadDocument(action.document).subscribe();
        })
      ),
    { dispatch: false }
  );

  uploadDocument$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DocumentsActions.uploadDocument),
      switchMap(({ dto }) => {
        return this._administrationDocumentService
          .uploadDocument(dto)
          .pipe(map(() => DocumentsActions.uploadDocumentSuccess()));
      }),
      catchError(error => {
        this._store.dispatch(DocumentsActions.uploadDocumentFailure());
        return throwError(error);
      })
    )
  );

  uploadDocumentSuccess$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(DocumentsActions.uploadDocumentSuccess),
      map(() => {
        return AppActions.httpSuccess({ messageKey: { key: 'success.upload-document' } });
      })
    );
  });

  uploadDocumentFailure$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(DocumentsActions.uploadDocumentFailure),
      map(() => AppActions.httpFailure({ messageKey: { key: 'errors.upload-document' } }))
    );
  });

  deleteDocument$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DocumentsActions.deleteDocument),
      switchMap(({ document }) => {
        return this._administrationDocumentService
          .deleteDocument(document.id)
          .pipe(map(() => DocumentsActions.deleteDocumentSuccess()));
      })
    )
  );

  deleteDocumentSuccess$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(DocumentsActions.deleteDocumentSuccess),
      map(() => {
        return AppActions.httpSuccess({ messageKey: { key: 'success.delete-document' } });
      })
    );
  });

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<fromRoot.State>,
    private readonly _documentService: DocumentService,
    private readonly _administrationDocumentService: AdministrationDocumentService
  ) {}
}
